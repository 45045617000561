/* eslint-disable import/prefer-default-export */
import { optionSwiftMe } from '@modules/theme/helpers/menuSwiftMe';

export const optionsSeller = (t) => [
    {
        aclCode: 'seller_catalog',
        key: 'dashboard',
        label: t('menu:beranda'),
        url: '/seller/dashboard',
    },
    ...optionSwiftMe(t),
];

export default optionsSeller;
